<template>
  <div class="FormWrap" v-loading="loading">
    <mia-navback @back="back" :isShowSave="true" @submit="submitForm" />
    <div class="content">
      <mia-Card title="基础信息">
        <div style="margin-left: 4px" slot="content">
          <el-form
            ref="basicForm"
            :model="form"
            :rules="FormRules"
            label-width="140px"
          >
            <el-row>
              <el-col :span="11">
                <el-form-item label="客户名称" prop="name">
                  <el-input
                    v-model="form.name"
                    placeholder="请填写客户名称"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="客户编号" prop="code">
                  <el-input
                    v-model="form.code"
                    :disabled="forbid"
                    placeholder="请填写客户编号"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11">
                <el-form-item label="客户分类" prop="typeId">
                  <el-select
                    v-model="form.typeId"
                    style="width: 100%"
                    filterable
                    clearable
                    placeholder="请选择客户分类"
                  >
                    <el-option
                      v-for="(item, Index) in typeList"
                      :key="Index"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                </el-form-item>
              </el-col>

              <el-col :span="11">
                <el-form-item label="预设价格" prop="name">
                  <el-select
                    v-model="form.templateId"
                    style="width: 100%"
                    clearable
                    placeholder=""
                    @change="priceTemplateChange"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </mia-Card>
      <!-- <mia-Card title="价格优惠">
        <div style="margin-left: 4px" slot="content">
          <el-form
            ref="customerForm"
            :model="form"
            :rules="FormRules"
            label-width="140px"
          >
            <el-row>
              <el-col :span="11">
                <el-form-item label="优惠方式" prop="way">
                  <el-select
                    v-model="form.way"
                    style="width: 100%"
                    clearable
                    placeholder=""
                    @change="discountType"
                  >
                    <el-option label="无" :value="0"></el-option>
                    <el-option label="会员价" :value="1"></el-option>
                    <el-option label="折扣" :value="2"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11" v-if="vipFlag">
                <el-form-item label="会员价格策略" prop="templateId">
                  <el-select
                    v-model="form.templateId"
                    style="width: 100%"
                    clearable
                    placeholder=""
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="11" v-if="discountFlag">
                <el-form-item label="折扣(%)" prop="wayValue">
                  
                  <el-input
                    type="number"
                    v-model="form.wayValue"
                    placeholder="可输入1-100,例如50代表打五折"
                    oninput="this.value=this.value;this.value <= 0 || this.value>100 && (this.value = 1)"
                    :max="100"
                    :min="1"
                    @change="numberChange"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </mia-Card> -->
      <mia-Card title="联系人信息">
        <div style="margin-left: 4px" slot="content">
          <el-table
            :data="tableData"
            style="width: 100%"
            border
            @cell-click="tabClick"
            :cell-class-name="tableRowClassName"
          >
            <!-- <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  @click="removeLine(scope.row, scope.$index)"
                  :disabled="scope.$index == 0"
                  >删除</el-button
                >
                <el-button type="text" @click="addLine">添加</el-button>
              </template>
            </el-table-column> -->
            <el-table-column label="序号" align="center">
              <template slot-scope="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <!-- <el-table-column prop="isMain" label="主联系人" align="center">
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.isMain"
                  @change="switchChange(scope.row, scope.$index)"
                ></el-switch>
              </template>
            </el-table-column> -->
            <el-table-column prop="name" label="姓名" align="center">
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.index === clickRowIndex &&
                    tabClickLabel == scope.column.label
                  "
                >
                  <el-input
                    v-model="scope.row.name"
                    size="mini"
                    @blur="inputBlur(scope.row)"
                  ></el-input>
                </div>
                <span v-else>{{ scope.row.name }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="phone" label="手机号码" align="center">
              <template slot-scope="scope">
                <div
                  v-if="
                    scope.row.index === clickRowIndex &&
                    tabClickLabel == scope.column.label
                  "
                >
                  <el-input
                    v-model="scope.row.phone"
                    size="mini"
                    @blur="inputBlur(scope.row)"
                  ></el-input>
                </div>
                <span v-else>{{ scope.row.phone }}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="实名认证" align="center"
              ><template><span>否</span></template>
            </el-table-column> -->
          </el-table>
        </div>
        <!-- <div style="margin-left:4px" slot="content">
                    <el-form ref="companyForm" :model="form" :rules="FormRules" label-width="140px">
                        <el-row>
                            <el-col :span="11">
                                <el-form-item label="联系人姓名" prop="contact">
                                    <el-input v-model="form.contact" placeholder="请填写联系人姓名"></el-input>
                                </el-form-item>                       
                            </el-col>
                            <el-col :span="11" :push="1">
                                <el-form-item label="手机号码" prop="phone">
                                    <el-input v-model="form.phone" placeholder="请填写手机号码"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="11">
                                <el-form-item label="开户行" prop="bank">
                                    <el-input v-model="form.bank" placeholder="请填写开户行"></el-input>
                                </el-form-item>                       
                            </el-col>
                            <el-col :span="11" :push="1">
                                <el-form-item label="银行卡号" prop="bankCard">
                                    <el-input v-model="form.bankCard" placeholder="请填写银行卡号"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div> -->
      </mia-Card>
      <mia-Card title="其他信息">
        <div style="margin-left: 4px" slot="content">
          <el-form
            ref="companyForm"
            :model="form"
            :rules="FormRules"
            label-width="140px"
          >
            <el-row>
              <el-col :span="11">
                <el-form-item label="开户行" prop="bank">
                  <el-input
                    v-model="form.bank"
                    placeholder="请填写开户行"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="11" :push="1">
                <el-form-item label="银行卡号" prop="bankCard">
                  <el-input
                    v-model="form.bankCard"
                    placeholder="请填写银行卡号"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="11">
                <el-form-item label="身份证号" prop="bank">
                  <el-input
                    v-model="form.creditCode"
                    placeholder="请填写身份证号"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </mia-Card>
    </div>
  </div>
</template>
<script>
import {
  GetCustomerTypeList,
  GetCustomerList,
  AddCustomer,
  getCode,
  getYnyPriceTemplate,
} from "@/api/customer";
import { setInterval } from "@/until/auth.js";
export default {
  data() {
    const validateIdCard = (rule, value, callback) => {
      const reg =
        /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      if (value !== "") {
        if (reg.test(value)) {
          callback();
        } else {
          callback(new Error("身份证格式有误"));
        }
      } else {
        callback(new Error("身份证号不能为空"));
      }
    };
    const validatePhone = (rule, value, callback) => {
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (value !== "") {
        if (reg.test(value)) {
          callback();
          this.verificationflag = true;
        } else {
          callback(new Error("请输入正确的手机号"));
          this.verificationflag = false;
          this.isShowCaptcha = false;
        }
      } else {
        callback(new Error("请填写手机号码"));
        this.verificationflag = false;
        this.isShowCaptcha = false;
      }
    };
    return {
      options: [
        {
          id: "零售价",
          name: "零售价",
        },
        {
          id: "批发价",
          name: "批发价",
        },
      ],
      Config: {},
      clickRowIndex: null,
      tabClickLabel: "",
      tableData: [
        {
          isMain: true,
          name: "",
          phone: "",
        },
      ],
      discountFlag: false,
      vipFlag: false,
      loading: false,
      id: "",
      searcForm: {
        typeId: "",
        param: "",
      },
      forbid: false,
      form: {
        name: "",
        code: "",
        typeId: "",
        way: 0,
        templateId: "",
        wayValue: "",
        contactList: [],
        bank: "",
        bankCard: "",
        creditCode: "",
      },
      total: "",
      isShow: true,
      statusList: [
        { label: "零售商", value: 0 },
        { label: "农户", value: 1 },
      ],
      typeList: [],
      FormRules: {
        way: [{ required: true, message: "请选择优惠方式", trigger: "change" }],
        templateId: [
          { required: true, message: "请选择会员策略", trigger: "change" },
        ],
        name: [
          { required: true, message: "请填写客户名称", trigger: "blur" },
          {
            min: 1,
            max: 64,
            message: "名称长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
        code: [
          { required: true, message: "请填写客户编号", trigger: "blur" },
          {
            min: 1,
            max: 16,
            message: "名称长度在 1 到 16 个字符",
            trigger: "blur",
          },
        ],
        contact: [
          { required: true, message: "请填写联系人", trigger: "blur" },
          {
            min: 1,
            max: 16,
            message: "名称长度在 1 到 16 个字符",
            trigger: "blur",
          },
        ],
        phone: [{ required: true, trigger: "blur", validator: validatePhone }],
        bank: [
          {
            min: 1,
            max: 64,
            message: "名称长度在 1 到 64 个字符",
            trigger: "blur",
          },
        ],
        bankCard: [
          {
            min: 1,
            max: 19,
            message: "名称长度在 1 到 19 个字符",
            trigger: "blur",
          },
        ],
        creditCode: [{ trigger: "blur", validator: validateIdCard }],
      },
    };
  },
  created() {
    this.GetSupplierType();
    this.Config = JSON.parse(sessionStorage.getItem("config"));
  },
  mounted() {
    this.$refs["basicForm"].resetFields();
    // this.$refs["customerForm"].resetFields();
    this.$refs["companyForm"].resetFields();
  },
  methods: {
    switchChange(val, index) {
      if (this.tableData) {
        if (this.tableData.length == 1) {
          this.tableData[0].isMain = true;
          return;
        } else if (this.tableData.length == 2) {
          if (!this.tableData[0].isMain && !this.tableData[1].isMain) {
            this.tableData[0].isMain = true;
            return;
          }
        } else {
          if (
            !this.tableData[0].isMain &&
            !this.tableData[1].isMain &&
            !this.tableData[2].isMain
          ) {
            this.tableData[0].isMain = true;
          }
        }
      }
      if (index == 0) {
        if (!this.tableData[0].isMain) {
          return;
        }
        if (this.tableData.length == 1) {
          this.tableData[0].isMain = true;
        } else if (this.tableData.length == 2) {
          this.tableData[0].isMain = true;
          this.tableData[1].isMain = false;
        } else {
          this.tableData[0].isMain = true;
          this.tableData[1].isMain = false;
          this.tableData[2].isMain = false;
        }
      } else if (index == 1) {
        if (!this.tableData[1].isMain) {
          return;
        }
        if (this.tableData.length == 1) {
          this.tableData[0].isMain = false;
        } else if (this.tableData.length == 2) {
          this.tableData[0].isMain = false;
          this.tableData[1].isMain = true;
        } else {
          this.tableData[0].isMain = false;
          this.tableData[1].isMain = true;
          this.tableData[2].isMain = false;
        }
      } else {
        if (!this.tableData[2].isMain) {
          return;
        }
        if (this.tableData.length == 1) {
          this.tableData[0].isMain = false;
        } else if (this.tableData.length == 2) {
          this.tableData[0].isMain = false;
          this.tableData[1].isMain = false;
        } else {
          this.tableData[0].isMain = false;
          this.tableData[1].isMain = false;
          this.tableData[2].isMain = true;
        }
      }
    },
    priceTemplateChange(val) {
      if (val === "零售价") {
        this.form.way = "3";
      } else if (val === "批发价") {
        this.form.way = "4";
      } else {
        this.form.way = "1";
      }
    },
    numberChange(val) {
      if (val == 0) {
        this.form.wayValue = 1;
      }
    },
    removeLine(row, index) {
      this.tableData.splice(index, 1);
    },
    addLine() {
      if (this.tableData && this.tableData.length >= 3) {
        this.$message({
          type: "warning",
          message: "最多添加三个",
        });
        return;
      }
      const obj = {
        isMain: false,
        name: "",
        phone: "",
      };
      this.tableData.push(obj);
    },
    tableRowClassName({ row, rowIndex, columnIndex, column }) {
      // 把每一行的索引放进row
      row.index = rowIndex;
    },
    // 控制input显示 row 当前行 column 当前列
    tabClick(row, column, cell, event) {
      this.clickRowIndex = row.index;
      this.tabClickLabel = column.label;
    },
    inputBlur(row) {
      this.clickRowIndex = null;
      this.tabClickLabel = "";
    },
    discountType(val) {
      if (val == 0) {
        this.vipFlag = false;
        this.discountFlag = false;
      } else if (val == 1) {
        this.vipFlag = true;
        this.discountFlag = false;
      } else {
        this.vipFlag = false;
        this.discountFlag = true;
      }
    },
    async getYnyPriceTemplate() {
      let res = await getYnyPriceTemplate();
      if (res.code == 1) {
        this.options = this.options.concat(res.data);
      }
    },
    // 获取客户类别
    GetSupplierType() {
      GetCustomerTypeList().then((res) => {
        if (res.code === 1) {
          const { records } = res.data;
          this.typeList = records;
          // 默认选中
          this.form.typeId = this.typeList[0].id;
          this.forbid = false;
          this.getData();
          if (this.Config.isMemberPrice === "1") {
            this.getYnyPriceTemplate();
          }
        } else {
          this.typeList = [];
          this.$message.error("未获取到客户分类");
        }
      });
    },
    getData() {
      getCode().then((res) => {
        if (res.code === 1) {
          this.form.code = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    //
    changeStatus(value) {
      this.isShow = Boolean(value);
    },
    back() {
      this.$emit("back");
    },
    // 保存
    submitForm() {
      this.$refs["basicForm"].validate((valid) => {
        if (valid) {
          this.$refs["companyForm"].validate((valid) => {
            if (valid) {
              const last = this.tableData[this.tableData.length - 1];
              if (!last.name) {
                return this.$message.error("请填写联系姓名");
              }
              if (!last.phone) {
                return this.$message.error("请填写联系电话");
              }
              this.tableData.forEach((item) => {
                if (item.isMain) {
                  item.isMain = 1;
                } else {
                  item.isMain = 0;
                }
              });
              if (this.form.way == "3") {
                this.form.templateId = "";
                this.form.wayValue = "";
              } else if (this.form.way == "4") {
                this.form.templateId = "";
                this.form.wayValue = "";
              }
              this.form.contactList = this.tableData;
              AddCustomer(this.form).then((res) => {
                const { code, msg } = res;
                if (code === 1) {
                  this.$message.success(msg);
                  this.$emit("back");
                  this.$emit("refresh");
                } else {
                  this.$message.error(msg);
                }
              });
            } else {
              return false;
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>
